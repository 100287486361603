<template>
  <div class="roleManage ">
    <div class="top box">
      <ul>
        <li><span>角色名称:</span><el-input v-model="search_data.role_title" size="mini" placeholder="请输入角色名称"></el-input></li>
        <!-- <li><span>权限字符:</span><el-input v-model="search_data.role_flag" size="mini" placeholder="请输入权限字符"></el-input></li> -->
        
        <li>
          <span>归属机构:</span>
          <el-select
            class="langInput"
            v-model="search_data.org_id"
            filterable
            size="mini"
            placeholder="请选择归属机构"
            clearable
          >
            <el-option
              v-for="item in orgList"
              :key="item.org_id"
              :label="item.name"
              :value="item.org_id"
            ></el-option>
          </el-select>
        </li>
       
        <li><span>创建时间:</span>
          <timePickerCloud ref="timePickerCloud" :pickerOptions="pickerOptions" :end_stats_dayOld="search_data.end_time" :start_stats_dayOld="search_data.start_time" @setTime="setTime" ></timePickerCloud>
        </li> 
        <li><span>角色状态:</span>
          <el-select v-model="search_data.visible" size="mini" placeholder="请选择角色状态" clearable>
            <el-option
              v-for="item in option_zt"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </li>
        <li> 
          <el-button type="primary" size="mini" @click="search_get_system_role">搜索</el-button>
          <el-button type="warning" size="mini" @click="re_search_data">重置</el-button>
         </li>
      </ul>
    </div>
    <div class="body box">
      <div class="btn-box">
        <el-button type="primary" size="mini" :disabled="btn_add_disabled" @click="open_addRole_dialog">新增</el-button> 
        <el-button type="warning" size="mini" :disabled="btn_exit_disabled" @click="open_editRole_dialog()">编辑</el-button> 
        <el-popconfirm
          confirm-button-text='好的'
          cancel-button-text='不用了'
          icon="el-icon-info"
          icon-color="red"
          :title="delData_list.length!=1?'确定删除这些角色吗？':'确定删除该角色吗？'"
          @confirm="del_system_role(delData_list)"
          >
          <el-button slot="reference" type="danger"  :disabled="btn_del_disabled" style="margin:0 10px;" size="mini">删除</el-button>
        </el-popconfirm>
      </div>
      <div >
        <el-table
          style="margin-bottom: 20px;"
          :data="tableData"
          header-align="center"
          v-loading="loading_table"
          stripe
          size="small"
          @selection-change="selectionchange"
          >
          <el-table-column
            type="selection"
            align="center"
            width="55">
          </el-table-column>
          <el-table-column
            align="center"
            prop="role_id"
            label="角色ID"
            :width="flexColumnWidth(135)">
          </el-table-column>
          <el-table-column
            align="center"
            prop="role_title"
            label="角色名称">
          </el-table-column>
          <!-- <el-table-column
            align="center"
            prop="role_flag"
            label="权限字符"
            :width="flexColumnWidth(100)"
            >
          </el-table-column> -->
          <el-table-column
            align="center"
            prop="org_name"
            label="归属机构"
            :width="flexColumnWidth(150)"
            >
          </el-table-column>
          <el-table-column
            align="center"
            prop="priority"
            label="显示顺序"
            :width="flexColumnWidth(100)">
          </el-table-column>
          <el-table-column
            align="center"
            prop="visible"
            label="角色状态"
            :width="flexColumnWidth(100)"
            >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.visible"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="changeVisible(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="create_time"
            label="创建时间"
            :width="flexColumnWidth(140)"
            >
            <template slot-scope="scope">
              <span v-html="scope.row.create_time.replace(/\s+/gm, '<br>')"></span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="操作"
           >
           <template slot-scope="scope">
            <div class="caozuo">
              <el-button type="warning" size="mini" @click="open_editRole_dialog(scope.row)">编辑</el-button> 
              <el-popconfirm

                confirm-button-text='好的'
                cancel-button-text='不用了'
                icon="el-icon-info"
                icon-color="red"
                title="确定删除该角色吗？"
                @confirm="del_system_role([scope.row.role_id])"
                >
                <el-button slot="reference" type="danger" style="margin:0 10px;" size="mini">删除</el-button>
              </el-popconfirm>
              <el-button size="mini" @click="open_permissions_dialog(scope.row)">权限设置</el-button> 
            </div>
          </template>
          </el-table-column>
         
        </el-table>
        <el-pagination
            :hide-on-single-page="total <= 0"
            background
            layout="total,prev, pager, next,jumper"
            :total="total"
            :page-size="search_data.size"
            :current-page.sync="search_data.page"
            @current-change="get_system_role"
          />
      </div>
    </div>
    <el-dialog
      :title="dialogType=='ADD'?'新增角色':'修改角色'"
      :visible.sync="dialogVisible_role"
      width="40%"
      >
      <div class="dialog-role-body">
        <ul>
          <li><span><i>*</i>角色名称:</span><p><el-input v-model="roleData.role_title" size="mini" placeholder="请输入角色名称"></el-input></p></li>
          <li>
            <span>
              <i>*</i>
              归属机构:
            </span>
            <p>
              <el-select
                class="langInput"
                v-model="roleData.org_id"
                filterable
                size="mini"
                placeholder="请选择归属机构"
                clearable
              >
                <el-option
                  v-for="item in orgList"
                  :key="item.org_id"
                  :label="item.name"
                  :value="item.org_id"
                ></el-option>
              </el-select>
            </p>
          </li>
         
          <!-- <li><span>权限字符:</span><p><el-input  v-model="roleData.role_flag" size="mini" placeholder="请输入权限字符"></el-input></p></li> -->
          <li><span><i>*</i>显示顺序:</span><p><el-input v-model="roleData.priority" size="mini" placeholder="请输入显示顺序"></el-input></p></li>
          <li><span>备注:</span><p><el-input v-model="roleData.role_desc" size="mini" placeholder="请输入备注"></el-input></p></li>
          <li><span>状态:</span><p>
            <el-switch
                v-model="roleData.visible"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
          </p></li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogType=='ADD'?add_system_role():put_system_role()" size="small">确 定</el-button>
        <el-button @click="dialogVisible_role = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="权限设置"
      :visible.sync="dialogVisible_permission"
      width="75%"
      >
      <div class="dialog-permission-body">
        <div>
          <TJDetailTitle titleData="基础信息" />
          <TJDetailMenu :dataList="dataList" />   
        </div>
        <div>
          <TJDetailTitle titleData="权限信息" />
          <div class="data-permission-box">
            <el-tabs type="border-card">
              <el-tab-pane label="菜单权限">
                <div class="menu-set-box">
                  <p>
                    <el-checkbox v-model="expandState" @change="change('expandState',expandState)">展开/折叠</el-checkbox>
                    <el-checkbox v-model="allChooseState" @change="change('allChooseState',allChooseState)">全选/全不选</el-checkbox>
                    <el-checkbox v-model="linkageState">父子联动</el-checkbox> 
                  </p>
                  <p class="template-menu">
                    <el-button v-for="(item,index) in menuTempList" :key="index" type="primary" plain @click="set_templateMenu(item)" size="mini">{{item.text}}</el-button>
                  </p>
                  <p>
                    <el-tree
                      :data="menuPermission"
                      show-checkbox
                      default-expand-all
                      check-on-click-node
                      ref="meunTree"
                      node-key="menu_id"
                      highlight-current
                      :check-strictly="!linkageState"
                      :props="props_menu">
                    </el-tree>
                  </p>
                </div>
              </el-tab-pane>
              <el-tab-pane label="数据权限">
                <div class="menu-permission-box">

                  <div>
                    <p>
                      数据范围：
                    </p>
                    <div>
                      <el-select
                        v-model="data_range_value"
                        size="mini"
                        placeholder="请选择"
                        class="leftSelect"
                        filterable
                        clearable
                      >
                        <el-option
                          v-for="item in data_range_list"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>
                  <div>
                    <p>数据权限:</p>
                    <div class="data-permission-box">
                      <div class="allocation-box allocation-checkbox-box">
                        <span>选择地区</span>

                        <div >
                          <el-checkbox-group v-model="province_checkList" @change="change_province_checkList">
                            <el-checkbox v-for="(item,index) in province_list" :key="index"  :label="item.value">{{item.label}}</el-checkbox>
                          </el-checkbox-group>
                          <p class="city-box" v-show="province_choose_list.length!=0">
                            <el-checkbox v-model="allChoose_city" @change="change_allChoose_city">全选</el-checkbox>
                            <el-checkbox-group v-for="(item,index) in province_choose_list" :key="index" v-model="city_obj[item.value]" @change="$forceUpdate();show_selected_data('city_text');">
                              <el-checkbox v-for="(temp,indeX) in item.city_list" :key="indeX" :label="temp.value">{{temp.label}}</el-checkbox>
                            </el-checkbox-group>
                          </p>
                        </div>
                      </div>
                      <div class="sales-box">
                        <div class="search-box top">
                          <ul>
                            <li><span>经销商:</span><el-input v-model="sales_list_param.customer_name" size="mini" placeholder="请输入经销商"></el-input></li>
                            <li><span>医院:</span><el-input v-model="sales_list_param.hospital_name" size="mini" placeholder="请输入医院"></el-input></li>
                            <li><span>机号:</span><el-input v-model="sales_list_param.instrument_id" size="mini" placeholder="请输入机号(仅筛选厂内数据)"></el-input></li>
                            <li><el-button type="primary" size="mini" @click="sales_list_param.page=1;get_sales_list()">查找</el-button></li>
                          </ul>
                        </div>
                        <el-divider></el-divider>
                        <div class="sales-list-box">
                          <ul class="table-th">
                            <li>
                              <span>经销商</span>
                              <div>
                                <div>
                                  <span>医院</span>
                                  <p>机号</p>
                                </div>
                              </div>
                            </li>
                            <li><el-checkbox v-model="allChoose" @change="change_allChoose">全选</el-checkbox></li>
                          </ul>
                          <ul >
                            <li v-for="(item,index) in sales_list" :key="index">
                              <el-divider></el-divider>
                              <span><el-checkbox v-model="item.checked" @change="$forceUpdate();show_selected_data('customer_text')" v-show="item.customer_name">{{item.customer_name}}</el-checkbox></span>
                              <div>
                                <div v-for="(temp,index2) in item.hospital_info2" :key="index2">
                                  <span>
                                    <el-checkbox v-model="temp.checked" @change="$forceUpdate();show_selected_data('hospital_text')">{{ temp.hospital_full_name }}</el-checkbox>
                                  </span>
                                  <p>
                                    <span v-for="(e,index3) in temp.instrument_info2" :key="index3">
                                      <el-checkbox v-model="e.checked" @change="$forceUpdate();show_selected_data('instrument_text')">{{ e.label?e.label +'-':e.label }}{{ e.instrument_id }}</el-checkbox>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                         <el-button type="primary" size="mini" v-show="loading_more_show" @click="get_sales_list()">加载更多</el-button>
                          
                        </div>
                      </div>
                      <div class="allocation-box">
                        <span>数据禁用：</span>
                        <div class="pageData-box">
                            <el-checkbox-group v-model="pageData_checkList" @change="show_selected_data('page_text')">
                              <!-- <el-checkbox label="单价">单价</el-checkbox>
                              <el-checkbox label="总价">总价</el-checkbox> -->
                              <el-checkbox v-for="(item,index) in systemRolePage" :key="index" :label="item.role_page_api_id">{{item.note}}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                      </div>
                      <div class="selected-box">
                        <label>已选数据</label>
                        <div style="marginTop:0;">
                          <span>已选地区:</span>
                          <p>
                            {{city_text}}
                          </p>
                        </div>
                        <div>
                          <span>已选经销商:</span>
                          <p>
                            {{customer_text}}
                          </p>
                        </div>
                        <div>
                          <span>已选医院:</span>
                          <p>
                            {{hospital_text}}
                          </p>
                        </div>
                        <div>
                          <span>已选机号:</span>
                          <p>
                            {{instrument_text}}
                          </p>
                        </div>
                        <div>
                          <span>数据禁用:</span>
                          <p>{{page_text}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <p >
                    <el-checkbox v-model="expandState" @change="change('expandState',expandState)">展开/折叠</el-checkbox>
                    <el-checkbox v-model="allChooseState" @change="change('allChooseState',allChooseState)">全选/全不选</el-checkbox>
                    <el-checkbox v-model="linkageState">父子联动</el-checkbox> 
                  </p>
                  <p>
                    <el-tree
                      :data="dataPermission"
                      show-checkbox
                      default-expand-all
                      ref="dataTree"
                      highlight-current
                      :props="props_data">
                    </el-tree>
                  </p> -->
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
         
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="put_system_role_permissions" size="small">确 定</el-button>
        <el-button @click="dialogVisible_permission = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import manageMixins from './components/manageMixins.js'
import timePickerCloud from '@/components/timePickerCloud.vue';
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'
import api from '@/api/manage.js'
import { flexColumnWidth } from '@/assets/js/adaption.js'

export default {
  mixins: [manageMixins],
  components: {timePickerCloud,TJDetailTitle, TJDetailMenu },
  data() {
    return {
      loading_table: false,
      total: 0,
      input: '',
      pickerOptions: {},
      start_stats_day: '',
      end_stats_day: '',
      search_data: {
        start_time: '',
        end_time: '',
        role_title: '',
        org_id: '',
        role_flag: '',
        visible: '',
        page: 1,
        size: 10,
      },
      option_zt: [
        {
          value: 'True',
          label: '显示'
        },{
          value: 'False',
          label: '隐藏'
        }
      ],
      btn_add_disabled: false,
      btn_exit_disabled: true,
      btn_del_disabled: true,
      dialog_roleData: '',
      tableData: [],
      dialogType: 'ADD',
      roleData: {
        role_title: '',
        role_flag: '',
        role_desc: '',
        priority: null,
        visible: true,
      },
      delData_list: [],
      expandState: true,
      allChooseState: false,
      linkageState: true,
      treeAllChildNode: [],
      props_menu: {
        label: 'menu_title',
        id: 'menu_id',

      },
      orgList: [],
      props_data: {},
      systemRolePage: [],
      menuPermission: [
        {
          id: 1,
          label: '一级 1',
          children: [{
            id: 4,
            label: '二级 1-1',
            children: [{
              id: 9,
              label: '三级 1-1-1'
            }, {
              id: 10,
              label: '三级 1-1-2'
            }]
          }]
        }, {
          id: 2,
          label: '一级 2',
          children: [{
            id: 5,
            label: '二级 2-1'
          }, {
            id: 6,
            label: '二级 2-2'
          }]
        }, {
          id: 3,
          label: '一级 3',
          children: [{
            id: 7,
            label: '二级 3-1'
          }, {
            id: 8,
            label: '二级 3-2'
          }]
        }
      ],
      
      dataPermission: [
        {
          id: 1,
          label: '一级 1',
          children: [{
            id: 4,
            label: '二级 1-1',
            children: [{
              id: 9,
              label: '三级 1-1-1'
            }, {
              id: 10,
              label: '三级 1-1-2'
            }]
          }]
        }, {
          id: 2,
          label: '一级 2',
          children: [{
            id: 5,
            label: '二级 2-1'
          }, {
            id: 6,
            label: '二级 2-2'
          }]
        }, {
          id: 3,
          label: '一级 3',
          children: [{
            id: 7,
            label: '二级 3-1'
          }, {
            id: 8,
            label: '二级 3-2'
          }]
        }
      ],
      title: '',
      dialogVisible_role: false,
      state: false,
      dialogVisible_permission: false,
      dataList: [
        {
          name: '角色名称',
          value: '',
          width: '50%',
        },
        {
          name: '权限字符',
          value: '',
          width: '25%',
        },
      ],
      data_range_value: '',
      data_range_list: [],
      province_list: [],
      city_checkList: [],
      province_checkList: [],
      province_choose_list: [],
      city_obj: {
      },
      now_row: {},
      instrument_info_list: [], //原始列表数据
      sales_list: [],
      sales_list_param: {
        customer_name: '',
        hospital_name: '',
        instrument_id: '',
        page: 1,
      },
      loading_more_show: false,
      pageData_checkList: [],
      city_text: '',
      customer_text: '',
      hospital_text: '',
      instrument_text: '',
      page_text: '',
      allChoose: false,
      allChoose_city: false,
      menuTempList: [
        {
          id: 1,
          text: '经销商内勤',
          list: [63,64,66,67,69,70,71,72,73,74,75,76,77,78,79,80,83,1,7,8,9,2,10,11,13,14,15]
        },
        {
          id: 2,
          text: '工程师',
          list: [63,64,65,66,67,69,70,71,72,73,74,75,76,77,78,79,80,83,1,7,8,9,2,10,12,13,14,15,4,54]
        },
        {
          id: 3,
          text: '医院用户',
          list: [63,64,69,70,71,72,73,74,75,76,77,78,79,80,83,1,8,9,2,10,14]
        },
      ]
    }
  },
  created(){
    this.get_system_role();
    this.get_system_menu();
    // this.get_system_instrument_info();
  },
  mounted() {
    this.province_list = JSON.parse(localStorage.getItem('province'));
    this.$refs.timePickerCloud.setTime('','')
    this.search_data.start_time = ''
    this.search_data.end_time = ''
    this.get_system_org();
    this.get_system_role_page_api();
  },
  methods: {
    // 宽度
    flexColumnWidth(width, minWidth) {
      return flexColumnWidth(width, minWidth)
    },
    // 获取系统管理组织机构架构列表
    get_system_org() {
      this.loadingShow('加载中')
      api
        .system_org({ size: 'all' })
        .then((res) => {
          this.orgList = res.data
        })
        .finally((msg) => {
          this.loading.close()
        })
    },
    // 新增弹窗
    open_addRole_dialog() {
      this.roleData = {
        role_title: '',
        role_flag: '',
        role_desc: '',
        priority: null,
        visible: true,
      }
      this.dialogType = 'ADD';
      this.dialogVisible_role = true;
    },
    // 修改弹窗
    open_editRole_dialog(row=this.dialog_roleData) {
      this.roleData = {
        role_id: row.role_id,
        role_title: row.role_title,
        role_flag: row.role_flag,
        role_desc: row.role_desc,
        org_id: row.org_id,
        priority: row.priority,
        visible: row.visible,
      }
      this.dialogType = 'EDIT';
      this.dialogVisible_role = true;
    },
    // 打开获取角色权限弹窗
    open_permissions_dialog(row) {
      this.dataList[0].value = row.role_title
      this.dataList[1].value = row.role_flag
      this.now_row = row
      this.linkageState = false
      this.allChoose = false
      this.loadingShow('请稍后')
      api.system_role_permissions({role_id:row.role_id}).then(res => {
        let province_checkList = []
        let city_obj = {}
        res.data.data_permission.province_data.forEach(temp => {
          province_checkList.push(temp.province_id);
          city_obj[temp.province_id] = temp.city_list.map(e => e.city_id)
        })
        this.province_checkList = province_checkList
        // this.change_province_checkList(province_checkList) 
        //设置省份
        this.province_choose_list = this.province_list.filter(e => province_checkList.includes(e.value))
      // 原来有的不变，原来没有的增加且全选
      for(let key in this.city_obj){
        if(!province_checkList.includes(Number(key))){
          delete this.city_obj[key]
        }
      }
      let city_obj_keys = Object.keys(this.city_obj).map(e => Number(e))
      this.province_choose_list.forEach(e => {
        if(!city_obj_keys.includes(e.value)){
          this.city_obj[e.value] = e.city_list.map(temp => temp.value)
        }
      })
        this.city_obj = city_obj //设置地区
        // 获取应该获取的经销商医院信息
        let city_list = [];
        for(let key in this.city_obj) {
          city_list.push(...this.city_obj[key])
        }
        let customer_list = res.data.data_permission.customer_data
        let hospital_list = res.data.data_permission.hospital_data.map(e => e.hospital_id)

        let instrument_list = res.data.data_permission.instrument_data
        this.instrument_info_list = []
        api.system_instrument_info({city_list,province_list:this.province_checkList}).then(res2=> {
          this.instrument_info_list = res2.data
          this.instrument_info_list.forEach(item => {
            // item.checked = true
            item.checked = customer_list.includes(item.customer_id) 
            item.hospital_info.forEach(temp => {
              // temp.checked = true
              temp.checked = hospital_list.includes(temp.hospital_id) 
              temp.instrument_info.forEach(e => {
                // e.checked = true
                e.checked = instrument_list.includes(e.instrument_id)
              })
            })
          })
          this.sales_list_param = {
            customer_name: '',
            hospital_name: '',
            instrument_id: '',
            page: 1
          }
          this.get_sales_list()
        })
        // 设置数据禁用
        this.pageData_checkList = res.data.data_permission.page_data.map(e => e.role_page_api_id)
        this.show_selected_data('page_text')
        // 设置地区文字
        let text = '';
        let noCity = [];
        this.province_choose_list.forEach(e=> {
          e.city_list.forEach(temp => {
            if(!this.city_obj[e.value].includes(temp.value)){
              noCity.push(temp.label)
            }
          })
          if(noCity.length!=0){
            text += `${e.label}全部(不含:${noCity.join('、')}) `
          }else{
            text += `${e.label}全部 `
          }
          noCity = [];
        })
        this.city_text = text;

        //设置功能树
        this.dialogVisible_permission = true;
        this.$nextTick(()=> {
          this.$refs.meunTree.setCheckedKeys(res.data.menu_permission)
          this.linkageState = true
        })
        
      }).finally(msg => {
        this.loading.close();
      })

    },

    // 角色选择
    selectionchange(selection) {
      if(selection.length==0){
        this.btn_exit_disabled = true;
        this.btn_del_disabled = true;
        return
      }
      if(selection.length==1){
        this.dialog_roleData = selection[0]
        this.btn_exit_disabled = false;
      }else{
        this.btn_exit_disabled = true;
      }
      this.btn_del_disabled = false;
      this.delData_list = selection.map(e=>e.role_id)

    },
    // 点击搜索
    search_get_system_role() {
      this.search_data.page = 1;
      this.get_system_role();
    },
    // 获取角色列表
    get_system_role(){
      this.loading_table = true
      api.system_role(this.search_data).then(res=> {
        this.loading_table = false
        this.tableData = res.data.data
        this.total = res.data.count
      })
    },
    // 重置
    re_search_data() {
      this.$refs.timePickerCloud.setTime('','')
      this.search_data = {
        start_time: '',
        end_time: '',
        org_id: '',
        role_title: '',
        role_flag: '',
        visible: '',
        page: 1,
        size: 10,
      }
    },
    // 修改时间
    setTime(val) {
      this.search_data.start_time = val.start_stats_day?val.start_stats_day.substring(0,4)+'-'+ 
                              val.start_stats_day.substring(4,6)+'-'+ 
                              val.start_stats_day.substring(6,8):'';
      this.search_data.end_time = val.end_stats_day?val.end_stats_day.substring(0,4)+'-'+
                            val.end_stats_day.substring(4,6)+'-'+
                            val.end_stats_day.substring(6,8):'';
    },
    // 页面切换状态
    changeVisible(row) {
      this.roleData = {
        role_id: row.role_id,
        role_title: row.role_title,
        org_id: row.org_id,
        priority: row.priority,
        visible: row.visible,
      }
      this.put_system_role();
    },
    // 新增角色
    add_system_role() {
      if (this.testParams(this.roleData, ['role_title','org_id','priority'],true) == false) {
        return
      }
      this.loadingShow('设置中')
      this.roleData.priority = Number(this.roleData.priority)
      api.system_role_add(this.roleData).then(res=> {
        this.close_role_dialog(true);
        this.$message.success('设置中')
        this.get_system_role();
      }).finally(msg => {
        this.loading.close();
      })
    }, 
    // 修改角色
    put_system_role() {
      if (this.testParams(this.roleData, ['role_title','org_id','priority']) == false) {
        return
      }
      this.loadingShow('修改中')
      this.roleData.priority = Number(this.roleData.priority)
      api.system_role_put(this.roleData).then(res=> {
        this.close_role_dialog(true);
        this.$message.success('修改成功')
        this.get_system_role();
      }).finally(msg => {
        this.loading.close();
      })
    },
    // 删除角色
    del_system_role(role_id_list) {
      this.loadingShow('删除中')
      api.system_role_delete({role_id_list}).then(res=> {
        this.close_role_dialog(true);
        this.$message.success('删除成功')
        this.get_system_role();
      }).finally(msg => {
        this.loading.close();
      })
    },
    
    // 关闭菜单弹窗open_addMenu_dialog
    close_role_dialog(clean=false) {
      if(clean){
        this.roleData = {
          role_title: '',
          role_flag: '',
          role_desc: '',
          priority: '',
          visible: true,
        };
      }
      this.dialogVisible_role = false;
    },
    // 获取系统角色API接口字段数据字典
    get_system_role_page_api() {
      api.system_role_page_api().then(res => {
        this.systemRolePage = res.data
      })
    },
    // 获取菜单列表
    get_system_menu() {
      api.system_menu().then(res=> {
        this.menuPermission = res.data
        this.getTreeAllChildNode(res.data)
      })
    },
    set_templateMenu(item) {
      let list = item.list
      for (let i = 0; i < this.treeAllChildNode.length; i++) {
        if(list.includes(this.treeAllChildNode[i])){
          this.$refs['meunTree'].setChecked(this.treeAllChildNode[i], true, false)
        }else {
          this.$refs['meunTree'].setChecked(this.treeAllChildNode[i], false, false)
        }
      }
    },
    // 获取角色管理仪器信息对应数据字典
    get_system_instrument_info() {
      let city_list = [];
      for(let key in this.city_obj) {
        city_list.push(...this.city_obj[key])
      }
      this.loadingShow('加载中')
      let customer_list = [],hospital_list = [],instrument_list = []
      this.instrument_info_list.forEach(item => {
        if(item.checked){
          customer_list.push(item.customer_id)
        }
        item.hospital_info.forEach(temp => {
          hospital_list.push(temp.hospital_id)
          temp.instrument_info.forEach(e => {
            if(e.checked){
              instrument_list.push(e.instrument_id)
            }
          })
        })
      })
      this.instrument_info_list = [];
      api.system_instrument_info({city_list,province_list:this.province_checkList,}).then(res=> {
        this.instrument_info_list = res.data,
        this.instrument_info_list.forEach(item => {
          // item.checked = this.allChoose;
          item.checked = customer_list.includes(item.customer_id) 
          item.hospital_info.forEach(temp => {
            // temp.checked = this.allChoose;
            temp.checked = hospital_list.includes(temp.hospital_id) 
            temp.instrument_info.forEach(e => {
              // e.checked = this.allChoose;
              e.checked = instrument_list.includes(e.instrument_id)

            })
          })
        })
        this.sales_list_param.page = 1;
        this.get_sales_list()
      }).finally(msg => {
        this.loading.close();
      })
    },
    // 全选地区
    change_allChoose_city(value) {
      this.province_choose_list.forEach(e => {
        this.city_obj[e.value] = value?e.city_list.map(temp => temp.value):[]
      })
      this.show_selected_data('city_text')
    },
    // 全选经销商医院机号
    change_allChoose(value) {
      this.instrument_info_list.forEach(item => {
        item.checked =value
        item.hospital_info.forEach(temp => {
          temp.checked = value
          temp.instrument_info.forEach(e => {
            e.checked = value
          })
        })
      })
      this.show_selected_data('customer_text')
      this.show_selected_data('hospital_text')
      this.show_selected_data('instrument_text')
    },
    // 展示出来的规则显示
    get_sales_list() {
      if(this.sales_list_param.page == 1){
        this.sales_list = [];
      }
      let temp = [];
      this.instrument_info_list.forEach(item => {
        let item_temp = item
        // if(this.sales_list_param.customer_name!=''){ //筛选经销商
        //   if(item.customer_name.includes(this.sales_list_param.customer_name)) { //经销商筛选中了
        //     if(this.sales_list_param.hospital_name!='') { //医院筛选
        //       item_temp.hospital_info2 = item.hospital_info.filter(e => e.hospital_full_name.includes(this.sales_list_param.hospital_name))
        //       if(item_temp.hospital_info2.length>0){temp.push(item_temp)}
        //     }else {
        //       item_temp.hospital_info2 = item.hospital_info
        //       temp.push(item_temp)
        //     }
        //   }
        // }else { //不筛选经销商
        //   if(this.sales_list_param.hospital_name!='') { //医院筛选
        //     item_temp.hospital_info2 = item.hospital_info.filter(e => e.hospital_full_name.includes(this.sales_list_param.hospital_name))
        //     if(item_temp.hospital_info2.length>0){temp.push(item_temp)}
        //   }else {
        //     item_temp.hospital_info2 = item.hospital_info
        //     console.log(item_temp)
        //     temp.push(item_temp)
        //   }
        // }
        if(this.sales_list_param.customer_name!=''&&!item.customer_name.includes(this.sales_list_param.customer_name)){
          return
        }
        if(this.sales_list_param.hospital_name!=''){
          item_temp.hospital_info2 = item.hospital_info.filter(e => e.hospital_full_name.includes(this.sales_list_param.hospital_name))
          if(item_temp.hospital_info2.length==0){
            return
          }
        }else{
          
          item_temp.hospital_info2 = item.hospital_info
        }
        if(this.sales_list_param.instrument_id!=''){
    
          item_temp.hospital_info2 = item_temp.hospital_info2.filter(temp => {
            temp.instrument_info2 = temp.instrument_info.filter(e => e.instrument_id === this.sales_list_param.instrument_id)
            return temp.instrument_info2.length!=0
          })
          if(item_temp.hospital_info2.length==0){
            return
          }
        }else {
          item_temp.hospital_info2.filter(temp => {
            temp.instrument_info2 = temp.instrument_info
          })
        }
        temp.push(item_temp)
      })
      this.sales_list.push(...temp.slice(10*(this.sales_list_param.page-1),10*this.sales_list_param.page))
      this.sales_list_param.page++
      this.show_selected_data('customer_text')
      this.show_selected_data('hospital_text')
      this.show_selected_data('instrument_text')

      this.loading_more_show = temp.length>this.sales_list.length
      // this.sales_list.push(...this.instrument_info_list.slice(10*(this.sales_list_param.page-1),10*this.sales_list_param.page))
    },
    // 省份选择切换
    change_province_checkList(value) {
      // 获取城市列表
      this.province_choose_list = this.province_list.filter(e => value.includes(e.value))
      // 原来有的不变，原来没有的增加且全选
      for(let key in this.city_obj){
        if(!value.includes(Number(key))){
          delete this.city_obj[key]
        }
      }
      let city_obj_keys = Object.keys(this.city_obj).map(e => Number(e))
      this.province_choose_list.forEach(e => {
        if(!city_obj_keys.includes(e.value)){
          this.city_obj[e.value] = e.city_list.map(temp => temp.value)
        }
      })
      this.show_selected_data('city_text');
    },
    // 已选数据显示
    show_selected_data(type){
      let text = '';
      switch (type) {
        case 'city_text':
          let noCity = [];
          this.province_choose_list.forEach(e=> {
            e.city_list.forEach(temp => {
              if(!this.city_obj[e.value].includes(temp.value)){
                noCity.push(temp.label)
              }
            })
            if(noCity.length!=0){
              text += `${e.label}全部(不含:${noCity.join('、')}) `
            }else{
              text += `${e.label}全部 `
            }
            noCity = [];
          })
          this.city_text = text;
          this.get_system_instrument_info()
          break;
        case 'customer_text':
          this.instrument_info_list.forEach(item => {
            if(item.checked){
              text += item.customer_name+ '、'
            }
          })
          this.customer_text = text
          break;
        case 'hospital_text':
          let hospital_info_tempList = []
          this.instrument_info_list.forEach(item => {
            item.hospital_info.forEach(temp => {
              if(temp.checked){
                hospital_info_tempList.push(temp.hospital_full_name)
                // text += temp.hospital_full_name+ '、'
              }
            })
          })
          hospital_info_tempList = [...new Set([...hospital_info_tempList])]
          this.hospital_text = hospital_info_tempList.join('、')
          break;
        case 'instrument_text':
          this.instrument_info_list.forEach(item => {
            item.hospital_info.forEach(temp => {
              temp.instrument_info.forEach(e => {
                if(e.checked){
                  text += (e.label?e.label +'-':'') + e.instrument_id+ '、'
                }
              })
            })
          })
          this.instrument_text = text
          break;
        case 'page_text':
          // this.page_text = `订单: ${this.pageData_checkList.join('、')}`
          this.page_text = this.systemRolePage.filter(e => this.pageData_checkList.indexOf(e.role_page_api_id)!=-1).map(e => e.note).join('、')
          break;
        default:
          break;
      }
    },
    // 菜单/数据权限提交
    put_system_role_permissions() {
      // 获取包含的菜单列 
      let menu_permission = [...this.$refs.meunTree.getCheckedKeys(),...this.$refs.meunTree.getHalfCheckedKeys() ]
      // 子菜单选择，父菜单必须选择
      const getmenu_permission= (list)=> {
        let temp = false; 
        list.forEach(e => {
          if(getmenu_permission(e.children)){
            temp = true;
            if(!menu_permission.includes(e.menu_id)){
              menu_permission.push(e.menu_id)
            }
          }else {
            if(menu_permission.includes(e.menu_id)){
              temp = true;
            }
          }
        })
        return temp
      }
      getmenu_permission(this.menuPermission)

      // 地区列表
      let city_permission = []
      for (const key in this.city_obj) {
        city_permission.push(...this.city_obj[key])
      }
      let customer_permission = []
      let hospital_permission = []
      let instrument_permission = []
      this.instrument_info_list.forEach(item => {
        if(item.checked&&item.customer_id){
          customer_permission.push(item.customer_id)
        }
        item.hospital_info.forEach(temp => {
          if(temp.checked){
            hospital_permission.push(temp.hospital_id)
          }
          temp.instrument_info.forEach(e => {
            if(e.checked){
              instrument_permission.push(e.instrument_id)
            }
          })
        })
      })
      customer_permission = customer_permission.filter(e => e!= null)
      // 页面隐藏字段
      let page_permission = this.pageData_checkList.map(e => { return {role_page_api_id: e}})
      let param = {
        role_id: this.now_row.role_id,
        menu_permission,
        data_permission: {
          city_permission,
          customer_permission,
          hospital_permission,
          instrument_permission,
          page_permission,
        }
      }
    
      this.loadingShow('新增中')
      api.system_role_permissions_put(param).then(res => {
        this.$message.success('设置成功')
        this.dialogVisible_permission = false;
        this.get_system_role();
      }).finally(msg => {
        this.loading.close();
      })
    },
    // 切换多选框筛选
    change(type,state) {
      switch (type) {
        case 'expandState':
          for (var i = 0; i < this.$refs.meunTree.store._getAllNodes().length; i++) {
            this.$refs.meunTree.store._getAllNodes()[i].expanded = state
          }
          break;
        case 'allChooseState':
          if (state) {
              // 	设置目前勾选的节点，使用此方法必须设置 node-key 属性
            // this.$refs.meunTree.setCheckedNodes(this.menuPermission);
            for (let i = 0; i < this.treeAllChildNode.length; i++) {
              this.$refs['meunTree'].setChecked(this.treeAllChildNode[i], true, false)
            }
          } else {
              // 全部不选中
            this.$refs.meunTree.setCheckedNodes([])
          }
          break;
        default:
          break;
      }
    },
    // 递归获取树所有可选择的子节点
    getTreeAllChildNode(treeData) {
      // 树长度为0 结束操作
      if (treeData.length === 0) {
        return
      }
      // 遍历树的数据
      for (let i = 0; i < treeData.length; i++) {
        // 当前遍历的数据是否还有子节点
        let children = treeData[i].children
        if (children && children.length > 0) {
          // 有，则递归
          this.treeAllChildNode.push(treeData[i].menu_id)
          this.getTreeAllChildNode(children)
        } else {
          // 没有，则判断当前节点是否是可选择状态
          // 将子节点id 追加给 treeAllChildNode （子节点数组也可声明为对象，将节点信息存储）
          this.treeAllChildNode.push(treeData[i].menu_id)
        }
      }
    },

  }
}
</script>

<style lang="scss" scoped>
.roleManage {
  margin: 10px 20px;
}
.box {
  background-color: #fff;
  padding: px(10);
  border-radius: px(8);
}
.top {
  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li {
      margin-top: px(8);
      display: flex;
      align-items: center;
      &>span {
        width: px(130);
      }
    }
    li:last-child {
      margin-left: px(20);
    }
  }
}
.body {
  margin-top: px(10);
  
}
.type-box {
  border-radius: px(8);
  padding: px(5) px(10);
  color: #fff;
}
.show-box {
  border-radius: px(18);
  padding: px(5) px(10);
  color: #fff;
}
.dialog-role-body {
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      width: 80%;
      display: flex;
      margin-bottom: px(10);
      align-items: center;
      span {
        width: px(130);
        text-align: right;
        margin-right: px(10);
        i {
          color: red;
          margin-right: px(5);
        }
      }
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
.data-permission-box {
 
}
.menu-permission-box {
  &>div {
    display: flex;
    justify-content: start;
    &>p {
      width: 10%;
    }
    &>div {
      width: 90%;
    }
  }
  .data-permission-box {
    &>div {
      padding: px(10);
      margin-top: px(10);
      box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    }
  }
  .allocation-checkbox-box {
    ::v-deep .el-checkbox {
      width: px(75);
    }
  }
  .allocation-box {
    display: flex;
    align-items: center;
    justify-content: start;
    &>span {
      width: 12%;
      font-weight: 500;
      font-size: px(20);
    }
    &>div {
      width: 85%;
    }
    
    .city-box {
      background-color: #eee;
      padding: px(10);
      border-radius: px(6);
    }
    .pageData-box {
      
      
    }
  }
  .selected-box {
    $boxheight: px(45);
    margin-top: $boxheight+px(10) !important;
    position: relative;
    &>label {
      display: inline-block;
      text-align: center;
      width: 12%;
      height: $boxheight;
      font-weight: 500;
      font-size: px(20);
      line-height: $boxheight;
      background-color: #eee;
      border-radius: px(6) px(6) 0 0;
      position: absolute;
      left: 0;
      top: -$boxheight;
    }
    &>div {
      display: flex;
      align-items: center;
      justify-content: start;
      padding: px(10);
      margin-top: px(10);
      box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
      &>span {
        width: 12%;
        font-weight: bold;
        font-size: px(20);
      }
      &>p {
        width: 85%;
       
      }
    }
  }
}
.sales-box {
  ::v-deep .el-divider {
    margin: px(20) 0;
  }
  .sales-list-box {
    
    .table-th {
      span {
        text-align: center;
      }
      p {
        text-align: center;
      }
    }
    &>ul{
      ::v-deep .el-checkbox__label{
        display: inline-grid;
        white-space: pre-line;
        word-wrap: break-word;
        overflow: hidden;
        padding-right: px(15);
       }
      &>li {  
        display: flex;
        flex-wrap: wrap;
        ::v-deep .el-divider {
          width: 100%;
        }
       
        &>span {
          width: 20%;
          height: 100%;
        }
        &>div { 
          width: 73%;
          &>div{ 
            margin-bottom: px(10);
            width: 80%;
            display: flex;
            flex-wrap: wrap;
            &>span {
              width: 40%;
            }
            &>p {
              width: 60%;
              &>span {
                margin-right: px(10);
              }
            }
          }
          &>div:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.menu-set-box {
  display: grid;
  gap: px(16) ;
}
.langInput {
  width: 300px;
  ::v-deep .el-input__inner{
    width: 300px;
  }
}
</style>